// Settings
$enable-rounded: false;

// Gutter
$grid-gutter-width: 20px;

// Font
$font-family-sans-serif: 'Montserrat', sans-serif;
$font-weight-normal: 500;
$font-size-base: 0.8125rem;

// Buttons + Forms
$input-btn-padding-x: .375rem;
$input-btn-font-size: 12px;

// Color
$gray-600: #7a7a7a;
$gray-800: #303030;

$primary: #AF00CF;
$secondary: $gray-800;

$primary-dark: #590066;
$text-color: $gray-800;