body {
  min-width: 320px;
  font-size: 13px;
}

// Modal
.modal-footer {
  justify-content: flex-start
}

// Img
img,
.img-fluid,
.img-responsive {
  max-width: 100%;
  height: auto;
}

// Buttons
.btn {
  display: flex;
  align-items: center;
  justify-content: space-around;

  .icon {
    font-size: 20px;
    padding-left: 10px;
    margin-right: -5px;
  }
}

// Title
.module-title {
  display: flex;
  align-items: center;
  font-weight: 800;
  font-size: 18px;
  margin-bottom: 15px;

  @include media-breakpoint-up(md) {
    font-size: 20px;

    .line {
      flex: 1 1 auto;
      height: 1px;
      background-color: $primary;
      margin-left: 20px;
    }
  }

  .swiper-pager {
    margin-left: auto;
    display: flex;
  }

  .swiper-button-next, .swiper-button-prev {
    position: static;
    width: auto;
    height: auto;
    margin-top: 0;
    margin-left: 20px;
    font-size: 25px;
    color: $primary;
    line-height: 1;
  }
}

// Swiper 
.swiper-viewport {
  position: relative;
}

.slideshow {
  overflow-x: hidden;

  .swiper-button-next, .swiper-button-prev {
    color: rgba(#eee, .9);
    font-size: 25px;
  }
}

.swiper-pager {
  .swiper-button-next, .swiper-button-prev {
    background: none;
  }
}

// Advantages
.advantages {
  padding: 1rem 0 0;

  &--item {
    display: flex;
  }
  
  &--icon {
    font-size: 45px;
    color: $primary;
    padding: 0 5px;
  }

  &--content {
    padding: 0 5px;
  }

  &--title {
    font-weight: 700;
    font-size: 15px;
    color: $text-color;
    line-height: 1.3;
  }

  &--text {
    font-weight: 500;
    font-size: 12px;
    color: $text-color;
    line-height: 1.2;
  }
}

// Featured categories
.f-category {
  &--title {
    font-weight: 800;
    font-size: 25px;
    text-transform: uppercase;
    text-align: center;
    margin-bottom: 20px;

    @include media-breakpoint-down(sm) {
      font-size: 18px;
    }
  }

  &--item {
    padding: 15px;
    margin: 15px;

    @include media-breakpoint-up(xl) {
      padding: 25px;
    }
  }

  &--image {
    margin-bottom: 15px;
  }

  &--name {
    text-align: center;
    font-weight: 700;
    font-size: 15px;
  }

  &--name-link {
    color: $text-color;
    
    &:hover {
      text-decoration: none;
      color: $primary;
    }
  }
}

// Products
@include media-breakpoint-down(xs) {
  .swiper-container.swiper-container-horizontal {
    margin-left: -10px;
    margin-right: -10px;
  }
}

.products {
  &--item {
    padding: 5px;
    margin: 3px;

    @include media-breakpoint-up(xl) {
      padding: 10px;
      margin: 15px;
    }

    @include media-breakpoint-up(sm) {
      margin: 10px;
    }
  }

  &--image {
    position: relative;

    a:hover {
      color: $primary;
    }
  }

  &--special,
  &--new {
    position: absolute;
    top: 0;
    font-weight: 600;
    font-size: 10px;
    line-height: 1.2;
    text-transform: uppercase;
    text-decoration: none;
    
    @include media-breakpoint-up(xs) {
      font-size: 11px;
    }
  }

  &--special {
    left: 0;
  }

  &--new {
    right: 0;
  }

  &--name {
    font-weight: 500;
    font-size: 11px;
    line-height: 1.2;

    min-height: 44px;

    @include media-breakpoint-up(sm) {
      font-size: 12px;
    }
  }

  &--name-link {
    color: $text-color;
    &:hover {
      text-decoration: none;
    }
  }

  &--stock,
  &--model {
    font-size: 11px;
    margin-bottom: 15px;
    white-space: nowrap;

    @include media-breakpoint-up(xl) {
      font-size: 12px;
    }
    
    @include media-breakpoint-up(xs) {
      font-size: 10px;
    }
  }
  
  &--model span {
    font-weight: 700;
  }

  &--price {
    font-weight: 800;
    font-size: 16px;
    color: $text-color;
    line-height: 1.2;
  }

  &--price-old {
    font-weight: 500;
    font-size: 14px;
    color: $gray-600;
    line-height: 1.2;
    text-decoration: line-through;
  }

  &--button {
    width: 100%;
    @include media-breakpoint-down(md) { 
      padding-left: 15px;
      padding-right: 12px;
      margin-left: auto;
      width: auto;
      span {
        display: none;
      }
      .icon {
        margin: 0;
        padding: 0;
      }
    }
  }
}

// Module special
.section-special {
}

// Section html
.section-html {
  &--title {
    margin-bottom: 20px;
    font-weight: 800;
    font-size: 18px;
    text-align: center;
    line-height: 1.2;

    @include media-breakpoint-up(sm) {
      font-size: 20px;
    }
  }
}