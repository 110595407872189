.top--row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 10px;
  margin-left: -8px;
  margin-right: -8px;
}

.c-nav-main {
  display: flex;
  padding: 0 0 10px 0;

  &--link {
    font-weight: 400;
    font-size: 12px;
    color: $white;
    padding: 0 5px;

    @include media-breakpoint-up(xl) {
      font-size: 13px;
      padding: 0 8px;
    }

    @include media-breakpoint-down(md) {
      &:not(.highlight) {
        display: none;
      }
    }
    
    &.highlight {
      font-weight: bold;
      text-decoration: underline;

      &:hover {
        text-decoration: none;
      }
    }

    &:hover {
      color: $gray-400;
      text-decoration: none;
    }
  }
}

@include media-breakpoint-down(sm) {
  .section-top {
    display: none;
  }
}

.c-nav-top {
  list-style-type: none;
  display: flex;
  align-items: center;
  margin: 0;
  padding: 0 0 10px 0;

  .icon {
    font-size: 14px;
    color: $white;
    padding-right: 3px;
    vertical-align: middle;

    @include media-breakpoint-up(xl) {
      padding-right: 5px;
    }
  }

  &--item {
    margin: 0 4px;

    @include media-breakpoint-up(xl) {
      margin: 0 8px;
    }
  }

  &--link {
    font-size: 11px;
    font-weight: 400;
    text-decoration: underline;
    color: $white;

    @include media-breakpoint-up(xl) {
      font-size: 13px;
    }

    &.highlight {
      text-decoration: none;
      font-weight: bold;
    }

    &:hover {
      color: $gray-400;
      text-decoration: none;
    }
  }
}

// Language
.language-select {
  font-weight: 500;
  font-size: 13px;
  color: $white;
  text-transform: uppercase;
  padding: 0 0 10px 0;
  display: inline-flex;

  &:hover {
    text-decoration: none;
    color: $gray-400;
  }

  &.active {
    color: $gray-400;
  }
}

// Header
.header {
  &--row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-left: -5px;
    margin-right: -5px;
  }

  &--logo {
    width: 20%;
    padding: 5px;
    @include media-breakpoint-down(sm) {
      width: 30%;
    }
  }
}

// Search
.header--search {
  flex: 1 1 auto;
  padding: 0 10px;

  @include media-breakpoint-down(sm) {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    transform: translateY(-100%);
  }
}

.c-search {
  position: relative;

  &--input {
    min-height: 44px;
    font-weight: 400;
    font-size: 12px;
    padding: 10px;
    border-radius: 3px;
    border-color: $primary-dark;

    &::placeholder {
      color: #a3a3a3;
    }
  }

  &--button {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    font-size: 25px;
    line-height: 26px;
    padding-right: 15px;
    color: $primary-dark;
    
    &:hover {
      color: $primary;
    }
  }
}

// Info
.c-info {
  display: flex;
  align-items: center;
  padding-right: 15px;

  @include media-breakpoint-down(sm) {
    display: none;
  }

  &--icon {
    font-size: 40px;
    color: $primary;
  }

  &--open {
    margin-bottom: 0;
    font-weight: 500;
    font-size: 12px;
    color: $text-color;
    line-height: 1.2;
  }

  &--telephone {
    font-weight: 600;
    font-size: 25px;
    color: $text-color;
    line-height: 1.2;

    &:hover {
      text-decoration: none;
    }
  }
}

// Cart
.header--cart {
  padding-left: 10px;
}

.cart {
  &--button {
    color: $primary;
    line-height: 1;

    .icon {
      font-size: 45px;
    }

    &:focus, &:hover, &:focus:hover, &:active {
      box-shadow: none;
    }
  }
}

// Menu
.c-catalog {
  display: flex;
  list-style-type: none;
  padding: 0;
  margin: 0;
  margin-left: -8px;
  margin-right: -8px;
  justify-content: space-between;

  @include media-breakpoint-down(md) {
    display: none;
  }

  &--link {
    display: block;
    padding: 15px 5px;
    font-weight: 600;
    font-size: 12px;
    color: $white;
    text-transform: uppercase;

    @include media-breakpoint-up(xl) {
      font-size: 13px;
      padding: 15px 8px;
    }

    &:hover {
      text-decoration: none;
      color: $white;
      background-color: rgba($black, .2);
    }
  }
}

// Mobile menu
.mobile-menu {
	background-color: rgba(0,0,0,.25);
	position: fixed;
	top: 55px;
	left: -100%;
	bottom: 0;
	z-index: 999;
	width: 100%;
	opacity: 0;
	overflow-y: auto;
	-webkit-transition: all .3s;
	transition: all .3s;

  &--container {
    background: #fff;
    min-height: 100%;
    max-width: 480px;
  }

  &--wrapper {
    display: block;
  }
}