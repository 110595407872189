.footer {
  padding: 35px 0;
}

.f-text {
  margin-top: 20px;
  font-weight: 500;
  font-size: 13px;
  line-height: 1.2;
}

.f-list {
  margin-bottom: 0;

  &--link {
    font-weight: 400;
    font-size: 13px;
    color: $text-color;

    &:hover {
      color: $primary;
      text-decoration: none;
    }
  }
}

.f-contact {
  &--item {
    font-weight: 500;
    font-size: 13px;
    color: $text-color;
    display: flex;
    margin-bottom: 5px;

    .icon {
      font-size: 18px;
      color: $primary;
      margin-left: 4px;
      margin-right: 12px;
      width: 1.25em;
      flex: 0 1 0;
    }
  }

  &--link {
    color: $text-color;
    &:hover {
      color: $primary;
      text-decoration: none;
    }
  }

  &--telephone {
    font-weight: 600;
    font-size: 25px;
    align-items: center;

    .icon {
      font-size: 27px;
      margin: 0 6px 0 0;
    }
  }
}